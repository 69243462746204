<template>
  <p class="hover-underline-animation">
  <v-btn flat class="navbar-btn" :ripple="false" @click="change_route()" variant="plain">
    {{ $t(text) }}
  </v-btn>
  </p>
  <v-spacer/>
</template>

<script>
export default {
  name: 'NavbarBtn',
  props: {
    text: String,
    route: String
  },
  methods: {
    change_route(){
      this.$router.push(this.route)
    }
  }
}

</script>

<style>
.navbar-btn{
  border-radius: 0 !important;
  opacity: 1 !important;
}

@media screen and (max-width: 960px) {
  .navbar-btn {
    width: 100%;
    background-color: var(--v-primary-base) !important;
    color: white !important;
}
 .hover-underline-animation{
    width: 100%;
 }
}

@media screen and (min-width: 960px) {
  .hover-underline-animation {
  display: inline-block;
  position: relative;
  color: white;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 5px;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
}

</style>