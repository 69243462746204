<template>
  <img
      src="@/assets/home.jpg"
      alt="Logo"
      width="100%"
      min-height="50%"
    >
    <v-icon class="go_down_home">mdi-arrow-down</v-icon>
  <v-container style="max-width: 1400px; align-items: center;">
    <v-divider thickness="2" class="border-opacity-25"></v-divider>
    <p class="presentation_text">Ventura Drive è un azienda affermata tra Milano e Bergamo per i servizi di trasporto con conducente.<br/>
      La nostra flotta di veicoli moderni e ben tenuti garantisce ai nostri clienti un viaggio confortevole, <br/>
      sicuro e puntuale, rendendo il viaggio un esperienza impeccabile.<br/>
      Il nostro personale, altamente qualificato e cortese, si impegna a fornire <br/>
      un servizio personalizzato secondo le vostre esigenze<br/>
    </p>
    <div class="services_container">
    <h1 class="text1">I nostri servizi:</h1>
    <br/>
    <v-row>
      <v-col>
        <v-card
          class="mx-auto"
          max-width="500"
          elevation="0"
        >
          <v-card-title><v-icon class="services_item" icon="mdi-airplane"></v-icon><span class="text2">Trasporti Aeroportuali</span></v-card-title>
          <v-card-text class="text3">
            Nella zona di Bergamo e Milano sono presenti tre aeroporti: Malpensa, Linate e Orio al Serio. <br/>
            Offriamo una soluzione comoda per il vostro arrivo o partenza, con un servizio di trasporto aeroportuale <br/>
          </v-card-text></v-card>
      </v-col>
      <v-col>
        <v-card
          class="mx-auto"
          max-width="500"
          elevation="0"
        >
          <v-card-title><v-icon class="services_item" icon="mdi-map-marker"></v-icon><span class="text2">Tour di punti di interesse</span></v-card-title>
          <v-card-text class="text3">
            Scopri i luoghi più belli e interessanti della Lombardia in completa comodità. <br/>
            Grazie al nostro servizio potrai raggiungere qualunque destinazione tu desideri. <br/>
          </v-card-text></v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          class="mx-auto"
          max-width="500"
          elevation="0"
        >
          <v-card-title><v-icon class="services_item" icon="mdi-clock-check"></v-icon><span class="text2">Disposizioni orarie</span></v-card-title>
          <v-card-text class="text3">
            Se necessiti di un autista a tua disposizione per un periodo di tempo prolungato,
            possiamo offrirti un servizio di noleggio con conducente a disposizione oraria. <br/>
          </v-card-text></v-card>
      </v-col>
      <v-col>
        <v-card
          class="mx-auto"
          max-width="500"
          elevation="0"
        >
          <v-card-title><v-icon class="services_item" icon="mdi-map-marker-path"></v-icon><span class="text2">Trasferimenti per tratte lunghe</span></v-card-title>
          <v-card-text class="text3">
            Se il tuo viaggio continua oltre i confini della Lombardia, possiamo offrirti un servizio di trasporto
            per tratte lunghe, per raggiungere tutte le destinazioni più belle di Italia  <br/>
          </v-card-text></v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          class="mx-auto"
          max-width="500"
          elevation="0"
        >
          <v-card-title><v-icon class="services_item" icon="mdi-briefcase"></v-icon><span class="text2">Trasferimenti aziendali</span></v-card-title>
          <v-card-text class="text3">
            Molte aziende hanno bisogno di un servizio di trasporto per i propri dipendenti o clienti. <br/>
            Ventura Drive offre un servizio di trasporto aziendale su misura per le esigenze della tua azienda,
            garantendo un servizio di alta qualità e affidabilità. <br/>
          </v-card-text></v-card>
      </v-col>
      <v-col>
        <v-card
          class="mx-auto"
          max-width="500"
          elevation="0"
        >
          <v-card-title><v-icon class="services_item" icon="mdi-party-popper"></v-icon><span class="text2">Servizi per eventi speciali</span></v-card-title>
          <v-card-text class="text3">
            Per giorni speciali come matrimoni, compleanni, feste o eventi aziendali, 
            offriamo un servizio di trasporto personalizzato per rendere il tuo evento unico e indimenticabile. <br/>
          </v-card-text></v-card>
      </v-col>
    </v-row>
  </div>
  <h1 class="text1">Prenota subito:</h1>
  <v-row style="text-align: center;">
    <v-col>
      <v-icon icon="mdi-cursor-default-click" class="contact_icon"></v-icon> <br/>
      <v-btn :ripple="false" variant="plain" size="x-large" @click="console.log('ok')">Prenota e paga subito online</v-btn>
    </v-col>
    <v-col>
      <v-icon icon="mdi-phone" class="contact_icon"></v-icon> <br/>
      <v-btn :ripple="false" variant="plain" size="x-large" @click="console.log('ok')">+39 333 1234567</v-btn>
    </v-col>
  </v-row>
  <v-row style="text-align: center;">
    <v-col>
      <v-icon icon="mdi-email-fast" class="contact_icon"></v-icon> <br/>
      <v-btn :ripple="false" variant="plain" size="x-large" @click="console.log('ok')">info@venturadrive.it</v-btn>
    </v-col>
    <v-col>
      <v-icon icon="mdi-whatsapp" class="contact_icon"></v-icon> <br/>
      <v-btn :ripple="false" variant="plain" size="x-large" @click="console.log('ok')">+39 333 1234567</v-btn>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HomeView',
 
}
</script>

<style>
.presentation_text {
  font-size: 25px;
  text-align: center;
  font-family: 'Minusc';
  opacity: 0.5;
}

.animation_text{
  font-size: 150px;
  text-align: center;
  font-family: 'Mid';
}

.go_down_home{
  width: 100% !important; 
  color: transparent; 
  margin-top: -150px; 
  font-size: 100px !important;
}
.go_down_home:hover{
  color: white;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.services_item{
  background-color: #003366; 
  border-radius: 50%;
  padding: 30px;
  color: white;
  font-size: xx-large !important;
}
.text2{
  font-size: x-large;
  margin-left: 10px;
  margin-right: 10px;
}
.services_container{
  align-self: center;
}
.text1{
  font-size: 50px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
.text3{
  font-size: large !important;
}

.contact_icon{
  border-radius: 50%;
  padding: 30px;
  color: #003366;
  font-size: 50px !important;
}
</style>