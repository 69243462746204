import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import it from './locales/it.json'
import es from './locales/es.json'

function loadLocaleMessages() {
    const locales = [{en: en}, {it: it}, {es: es}]
    const messages = {}
    locales.forEach(locale => {
        const key = Object.keys(locale)[0]
        messages[key] = locale[key]
    })
    return messages
}

export default createI18n({
    locale: 'it',
    fallbackLocale: 'it',
    messages: loadLocaleMessages(),
})
