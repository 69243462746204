import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/fleet',
    name: 'fleet',
    component: () => import(/* webpackChunkName: "fleet" */ '../views/FleetView.vue')
  },
  {
    path: '/tours',
    name: 'tours',
    component: () => import(/* webpackChunkName: "tours" */ '../views/ToursView.vue')
  },
  {
    path: '/booking',
    name: 'booking',
    component: () => import(/* webpackChunkName: "booking" */ '../views/BookingView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FaqView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
