<template>
  <v-app>
    <!-- NavBar Mobile -->
    <v-app-bar v-if="$vuetify.display.smAndDown" flat color="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" size="x-large">
      </v-app-bar-nav-icon>

      <v-spacer/>

      <img
          src="@/assets/LogoWhite.png"
          alt="Logo"
          height="40px"
        >

      <v-spacer/>

      <v-select
        v-model="$i18n.locale"
        :items="$i18n.availableLocales"
        hide-selected
        bg-color="transparent"
        class="lang-select"
        hide-details
        variant="plain"
      >
      <template v-slot:selection="{ item }">
          <img @click="onClick"
          :src="imageRenderer(item.title)"
          :alt="item.title"
          height="40px"
          width="60px"
          >
      </template>
      <template #item="{ item, props: { onClick } }" >
        <div class="lang-option">
          <img @click="onClick"
          :src="imageRenderer(item.title)"
          :alt="item.title"
          height="40px"
          width="60px"
          >
        </div>
      </template>
      </v-select>
    </v-app-bar>

    <v-navigation-drawer v-if="$vuetify.display.smAndDown"
        v-model="drawer"
        temporary
        color="primary"
        style="justify-items: center;"
        >

      <NavbarBtn text="Home" route="/" ></NavbarBtn>
      <NavbarBtn text="Car Fleet" route="/fleet" ></NavbarBtn>
      <!--<NavbarBtn text="Tours" route="/tours" ></NavbarBtn>-->
      <NavbarBtn text="Price & Booking" route="/booking" ></NavbarBtn>
      <NavbarBtn text="About Us" route="/about" ></NavbarBtn>
      <!--<NavbarBtn text="Contact" route="/contact" ></NavbarBtn>-->
      <NavbarBtn text="FAQ" route="/FAQ" ></NavbarBtn>
    </v-navigation-drawer>

    <!-- NavBar Desktop -->
    <v-app-bar v-else flat color="primary">
      <v-app-bar-nav-icon class="disabled-btn">
        <img
          src="@/assets/LogoWhite.png"
          alt="Logo"
          height="40px"
          style="margin-left: 10px;"
        >
      </v-app-bar-nav-icon>

      <v-spacer/>
      
      <NavbarBtn text="Home" route="/" ></NavbarBtn>

      <NavbarBtn text="Car Fleet" route="/fleet" ></NavbarBtn>

      <!--<NavbarBtn text="Tours" route="/tours" ></NavbarBtn>-->

      <NavbarBtn text="Price & Booking" route="/booking" ></NavbarBtn>

      <NavbarBtn text="About Us" route="/about" ></NavbarBtn>
      
      <!--<NavbarBtn text="Contact" route="/contact" ></NavbarBtn>-->

      <NavbarBtn text="Lavora con noi" route="/" ></NavbarBtn>

      <NavbarBtn text="FAQ" route="/FAQ" ></NavbarBtn>

      <v-select
        v-model="$i18n.locale"
        :items="$i18n.availableLocales"
        hide-selected
        bg-color="transparent"
        class="lang-select"
        hide-details
        variant="plain"
      >
      <template v-slot:selection="{ item }">
          <img @click="onClick"
          :src="imageRenderer(item.title)"
          :alt="item.title"
          height="40px"
          width="60px"
          >
      </template>
      <template #item="{ item, props: { onClick } }" >
        <div class="lang-option">
          <img @click="onClick"
          :src="imageRenderer(item.title)"
          :alt="item.title"
          height="40px"
          width="60px"
          >
        </div>
      </template>
      </v-select>
    </v-app-bar>

    <!-- Main -->
    <v-main>
      <router-view/>
    </v-main>
    <div>
      <Footer/>
    </div>
  </v-app>

</template>

<script>
import NavbarBtn from './components/App/NavbarBtn.vue'
import Footer from './components/App/Footer.vue'

export default {
  name: 'App',
  components: {
    NavbarBtn,
    Footer
  },
  data() {
    return {
      drawer: false,
    }
  },
  methods: {
    imageRenderer(item){
      return require(`@/assets/flags/${item}.webp`)
    },
  },
}
</script>

<style>
  body{
    font-family: 'Mid';
  }
  @font-face {
  font-family: "Minusc";
  src: url(./font/Minusc.ttf);
  font-display: swap;
  }
  @font-face {
    font-family: "Mid";
    src: url(./font/Mid.ttf);
    font-display: swap;
  }
  @font-face {
  font-family: "Maiusc";
  src: url(./font/Maiusc.otf);
  font-display: swap;
}

.disabled-btn {
  pointer-events: none;
  margin-left: 20px;
}



.lang-select{
  margin-right: 15px;
  flex: none !important;

  .v-field__append-inner{
    display: none !important;
  }

  .v-field__input{
    padding: 0 !important;
  }
}

.lang-option{
  width: 60px;
  height: 40px;
  margin-bottom: 10px;
  margin-top: 5px;
  cursor: pointer;

}
.v-list{
    background-color: transparent !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}
.v-select__content{
   box-shadow: none !important; 
  }

.viewContainer{
  max-width: 1400px !important;
  @media (max-width: 960px) {
    padding: 0 !important;
  }
  
}

#a{
  outline: 1px solid greenyellow !important;
}
</style>