import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import "@mdi/font/css/materialdesignicons.css";

import VueGoogleMaps from '@fawmi/vue-google-maps'

import createDatetime from 'vue-datetime3'
import 'vue-datetime3/style.css'


import 'flag-icons/css/flag-icons.min.css';
import 'v-phone-input/dist/v-phone-input.css'
import { createVPhoneInput } from 'v-phone-input';


const vPhoneInput = createVPhoneInput({
  countryIconMode: 'svg',
});

const venturaTheme = {
    dark: false,
    colors: {
      background: '#FFFFFF',
      surface: '#FFFFFF',
      primary: '#003366',
      //'primary-darken-1': '#003366',
      secondary: '#03DAC6',
      //'secondary-darken-1': '#018786',
      //error: '#B00020',
      //info: '#2196F3',
      //success: '#4CAF50',
      //warning: '#FB8C00',
    },
  }

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
          mdi,
        },
      },
      theme: {
        defaultTheme: 'venturaTheme',
        themes: {
            venturaTheme,
        },
      },
})

createApp(App).use(store).use(router).use(i18n).use(vuetify)
  .use(createDatetime())
  .use(VueGoogleMaps,{load:{key:'AIzaSyBNUboNDoO8VdPpFzyYv664loAKXGtGBp8',v: 'quarterly',libraries: 'places'}})
  .use(vPhoneInput)
  .mount('#app')
